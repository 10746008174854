@import './vars.sass'
@import './fonts.sass'

@import '~bootstrap/scss/bootstrap.scss'

.abs-child
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%

.no-select
  -webkit-touch-callout: none /* iOS Safari */
  -webkit-user-select: none /* Safari */
  -khtml-user-select: none /* Konqueror HTML */
  -moz-user-select: none /* Firefox */
  -ms-user-select: none /* Internet Explorer/Edge */
  user-select: none /* Non-prefixed version, currently supported by Chrome and Opera */

.cursor-default
  cursor: default !important

.def-img
  background-repeat: no-repeat
  background-position: center
  background-size: cover
  // background-size: contain

.Button
  font-size: 14px
  line-height: 36px
  font-weight: bold
  padding: 0 36px
  display: block
  text-align: center
  cursor: pointer
  text-decoration: none
  &--primary
    color: $black !important
    background: linear-gradient(269.55deg, rgba(1, 254, 1, 1) 0.39%, rgba(0, 255, 224, 1) 95.73%)
    border: 1px solid #26DC38
    border-radius: 100px
    box-shadow: 0px 0px 10px 0px #01FE0180
  &--secondary
    color: $black !important
    background: linear-gradient(269.55deg, rgba(1, 254, 1, 1) 0.39%, rgba(0, 255, 224, 1) 95.73%)
    border: 1px solid #26DC38
    border-radius: 100px
    box-shadow: 0px 0px 10px rgba(1, 254, 1, 0.5)

.mb-20
  margin-bottom: 20px
.mb-32
  margin-bottom: 32px
.mb-neg32
  margin-bottom: -32px

ul
  list-style: none
  padding-left: 1rem
  margin-bottom: 0
  li
    position: relative
    margin-bottom: 1rem
    &::before
      content: ''
      position: absolute
      top: .5em
      left: -1em
      background-color: #FF00FB
      display: inline-block
      font-weight: bold
      width: .5em
      height: .5em
      border-radius: 10px

.Background,
.Viewer
  @extend .abs-child

.Background
  overflow: hidden

.SectionWithBackground
  position: relative
  width: 100%
  height: 100vh
  &__background
    @extend .abs-child
    background-size: cover
    background-repeat: no-repeat
    background-position: center center
    &--darkened
      &::after
        content: ''
        @extend .abs-child
        background-color: rgba(0, 0, 0, .35)
  &__container
    @extend .abs-child
    @extend .d-flex
    &__content
      @extend .d-flex
      @extend .flex-grow-1
      @extend .flex-column
      @extend .justify-content-center
      @extend .container
      opacity: 1
      transition: opacity $transition
      .ContentSlider--clicked &
        opacity: 0

// *
//   @extend .no-select

.Loader
  position: fixed
  top: 0
  left: 0
  width: 100vw
  height: 100vh
  z-index: 5000
  background-color: $black
  transition: opacity .9s
  &--transparent
    opacity: 0
  &::after
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 30px
    height: 30px
    background-color: white
    // box-shadow: 0px 0px 20px 0px #00c797
    transition: box-shadow .6s
    animation: flicker 3s linear infinite
    @keyframes flicker
      0%
        box-shadow: 0px 0px 35px 12px #00c797
      33%
        box-shadow: 0px 0px 35px 12px #e400da
      66%
        box-shadow: 0px 0px 35px 12px #00fd00
      100%
        box-shadow: 0px 0px 35px 12px #00c797

.App
  // width: 100%
  // height: 100%
  // position: relative

  .Header,
  .Footer
    width: 100%
    height: 64px
    @extend .d-flex
    @extend .flex-row
    @extend .align-items-center
    position: relative
    z-index: 100
    &__anchor
      cursor: pointer
      text-decoration: none
      margin-right: 35px
  .Header
    background-color: $black
    margin-bottom: -64px
    .Logo
      width: 100px
      height: auto
    &__anchor
      color: white
  .Footer
    // margin-top: -64px
    background-color: #01FE01
    &__link
      width: 40px
      height: 40px
      @extend .ms-5
    &__anchor
      color: black
      line-height: 40px

  .Section
    background-color: #F5F5F5

.Bot
  background-color: #F1F1F1
  border: 1px solid #FFFFFF
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08)
  border-radius: 24px
  padding: 20px 0

#About
  position: relative
  &::before
    content: ''
    position: absolute
    left: 50%
    bottom: 32px
    transform: translateX(-50%)
    width: 100%
    padding-top: 21.46%
    background-image: url(./img/line.svg)
    @extend .def-img
    z-index: -1

#Instructions
  background-color: #0B0B0B
  padding: 50px 0 30px
  *
    color: white
  .tile
    height: 100%
    background: rgba(241, 241, 241, 0.1)
    border: 1px solid rgba(255, 255, 255, 0.1)
    border-radius: 24px
    padding: 50px 20px
    @include media-breakpoint-down(lg)
      padding: 30px 18px
    @include media-breakpoint-down(md)
      padding: 26px 15px

#FAQ
  background-color: #0B0B0B
  padding-bottom: 100px
  *
    color: white
  .title
    background-color: rgba(255, 255, 255, 0.1)
    border: 1px solid rgba(255, 255, 255, 0.1)
    border-bottom: none
    border-top-left-radius: 12px
    border-top-right-radius: 12px
    font-weight: bold
    color: #01FE01
    padding: 15px 20px 15px
  .Dropdown
    @extend .d-flex
    @extend .flex-column
    @extend .align-items-center
    background-color: rgba(255, 255, 255, 0.1)
    border: 1px solid rgba(255, 255, 255, 0.1)
    &:not(&:last-of-type)
      border-bottom: none
    &:last-of-type
      border-bottom-left-radius: 12px
      border-bottom-right-radius: 12px
    &__title
      width: 100%
      cursor: pointer
      position: relative
      display: inline-block
      padding: 15px 0 15px
      @extend .d-flex
      &__text
        width: 100%
        @extend .flex-grow-1
        @extend .no-select
        padding: 0 20px
        font-weight: bold
        &::after
          content: ''
          position: absolute
          right: 32px
          top: 50%
          width: 32px
          height: 32px
          @extend .def-img
          background-image: url(./img/dropdown__arrow.svg)
          transform: translateY(-50%) rotate(-90deg)
          transition: transform .2s

    &__content
      width: 100%
      transition: height .2s
      overflow: hidden
      &__container
        padding: 63px 20px 48px
        @include media-breakpoint-down(md)
          padding: 32px 20px 48px
        @include media-breakpoint-down(sm)
          padding: 21px 20px 31px
        width: 100%
        @extend .d-flex
        @extend .flex-column
    &--opened
      .Dropdown__title__text::after
        transform: translateY(-50%) rotate(0deg)
