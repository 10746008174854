$green: #00FFC3
$black: #03020B
$transition: .2s ease-in-out

@function get-breakpoints($key: "md")
  @return map-get($container-max-widths, $key)

// $spacers: ( xxs: rem(20), xs: rem(40), s: rem(48), m: rem(64), l: rem(96), xl: rem(128) )

// GRID
$grid-columns: 12

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 1024px, xl: 1368px, xxl: 1600px )

$grid-gutter-width: 24px
// $gutters: ( 0: 0, 1: $grid-gutter-width, 2: $grid-gutter-width * 2, 3: $grid-gutter-width * 3, 4: $grid-gutter-width * 4, 5: $grid-gutter-width * 5 )

$container-max-widths: ( xs: 100vw, sm: 540px + $grid-gutter-width, md: 720px + $grid-gutter-width, lg: 960px + $grid-gutter-width, xl: 1304px + $grid-gutter-width, xxl: 1550px + $grid-gutter-width )
